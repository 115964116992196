import React from "react"
import Image from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"

const query = graphql`
  {
    file(relativePath: { eq: "pages/about/about-1.jpg" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const About = () => {
  const {
    file: {
      childImageSharp: { fluid },
    },
  } = useStaticQuery(query)

  return (
    <Wrapper className="section-center">
      <Image fluid={fluid} className="about-img" />
      <article className="about-text">
        <p>
          We are professionals with over 10 years experience in fireplace
          industry, providing wide range of services for different types of
          fireplaces including gas, solid fuel (wood &amp; coal) and bio-ethanol
          smart fire fuel.
        </p>
        <p>
          From small to commercial fireplace systems we can assist you with: gas
          safety tests and maintenance, gas burners installation and conversion,
          fireplace and stoves fitting, designing bespoke fireplaces and chimney
          systems, flue lining and chimney testing and sweeping.
        </p>
        <p>
          All Fire Skilled Ltd projects are run with attention to detail from
          the beginning to the very end. Working closely with builders,
          fireplace manufacturers and architects, we’ve been able to reach
          highest quality and safety standards and guarantee that you will be
          satisfied with our services.
        </p>
      </article>
    </Wrapper>
  )
}

export default About

const Wrapper = styled.main`
  .about-img {
    margin-bottom: 2rem;
    height: 100%;
  }
  .about-text p {
    line-height: 2;
  }
  @media screen and (min-width: 768px) {
    padding-bottom: var(--spacer-bottom);
  }
  @media screen and (min-width: 992px) {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    align-items: center;
    column-gap: 4rem;

    .about-img {
      grid-column: 1 / span 5;
      margin-bottom: 0;
    }
    .about-text {
      grid-column: 6 / -1;
    }
  }
`
