import React from "react"
import SectionTitle from "../components/SectionTitle"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import PageWrapper from "../components/layout/PageWrapper"
import About from "../components/content/About"

const AboutPage = () => {
  return (
    <Layout>
      <SEO
        title="Gas burners, stoves, fireplaces"
        description="We are fireplace and stoves installers with over 10 years experience"
      />
      <PageWrapper>
        <SectionTitle
          title="Fire Skilled Ltd"
          strapline="Gas burners, fireplace and stoves installations"
        />
        <About />
      </PageWrapper>
    </Layout>
  )
}

export default AboutPage
